import React, { useContext, useState } from 'react'
import {Appstate} from '../App' 
import GamepadIcon from '@mui/icons-material/Gamepad';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Groups2Icon from '@mui/icons-material/Groups2';
import WebhookIcon from '@mui/icons-material/Webhook';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Link, useLocation } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const Sidebar = () => {
  const useAppState = useContext(Appstate);
  const location = useLocation();

  return (
    <div>
      <div class={`sidebar ${useAppState.show ? 'sidebar-open' : 'md:sidebar-open sidebar-closed'} p-4 text-gray-400 text-sm flex overflow-y-auto flex-col items-center h-screen md:w-1/5 w-3/5 fixed top-0 left-0 transform transition-transform duration-300 ease-in-out opacity-95 md:opacity-100`}>
      <Link to={'/hold'}><h1 className='font-bold text-2xl font-mono text-white flex justify-center items-center'><img src='../MGP.png' className='h-12 mr-2' /> MGP-Defi</h1></Link>
        <ul>
          <Link to={'/hold/dashboard'} ><li className={`${location.pathname == '/hold/dashboard' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><GamepadIcon color='inherit' fontSize='medium' /></p>
            <p>Dashboard</p>
          </li></Link>
          <Link to={'/hold/deposit'} ><li className={`${location.pathname == '/hold/deposit' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><AccountBalanceIcon color='inherit' fontSize='medium' /></p>
            <p>Deposit</p>
          </li></Link>
          <Link to={'/hold/teams'} ><li className={`${location.pathname == '/hold/teams' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><Groups2Icon color='inherit' fontSize='medium' /></p>
            <p>Teams</p>
          </li></Link>
          <Link to={'/hold/split'} ><li className={`${location.pathname == '/hold/split' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><WebhookIcon color='inherit' fontSize='medium' /></p>
            <p>Split</p>
          </li></Link>
          <Link to={'/hold/wallet'} ><li className={`${location.pathname == '/hold/wallet' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><AccountBalanceWalletIcon color='inherit' fontSize='medium' /></p>
            <p>Wallet</p>
          </li></Link>
          <a href='https://hazecrypto.net/audit/MGP' target='_blank' ><li className={`${location.pathname == '/haze' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><img src='../haze.webp' className='h-6 grayscale' /></p>
            <p>Haze Crypto</p>
          </li></a>
          {/* {useAppState.isAdmin ? <Link to={'/a'} ><li className={`${location.pathname == '/a' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><AdminPanelSettingsIcon color='inherit' fontSize='medium' /></p>
            <p>Admin</p>
          </li></Link> : null}
          {useAppState.isAdmin2 ? <Link to={'/g'} ><li className={`${location.pathname == '/g' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><AdminPanelSettingsIcon color='inherit' fontSize='medium' /></p>
            <p>Growth</p>
          </li></Link> : null}
          {useAppState.isAdmin3 ? <Link to={'/f'} ><li className={`${location.pathname == '/f' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><AdminPanelSettingsIcon color='inherit' fontSize='medium' /></p>
            <p>Fee</p>
          </li></Link> : null} */}
        </ul>
      </div>  
    </div>
  )
}

export default Sidebar