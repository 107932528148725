import { useContext, useEffect, useState, useRef } from 'react';
import {Header, Sidebar} from './index';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Appstate } from '../App';
import { useSearchParams, useLocation, Link, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { Modal } from 'antd';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import { useTimer } from 'react-timer-hook';
import { Button } from '@mui/material'
import FileCopyIcon from '@mui/icons-material/FileCopy';
import React from 'react'
import { SocialIcon } from 'react-social-icons';

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return <p><span>{days.toString().padStart(2, '0')}</span> days, <span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

function MyTimer2({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
  } = useTimer({ expiryTimestamp});

  return <p><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

function MyTimer3({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return (
    <div className='w-full flex justify-around mt-10'>
      <div className='flex flex-col items-center'>
        <p className='text-3xl font-bold'>{days.toString().padStart(2, '0')}</p>
        <p className='mt-4 text-sm text-cyan-500 font-semibold'>days</p>
      </div>
      <div className='flex flex-col items-center'>
        <p className='text-3xl font-bold'>{hours.toString().padStart(2, '0')}</p>
        <p className='mt-4 text-sm text-cyan-500 font-semibold'>Hours</p>
      </div>
      <div className='flex flex-col items-center'>
        <p className='text-3xl font-bold'>{minutes.toString().padStart(2, '0')}</p>
        <p className='mt-4 text-sm text-cyan-500 font-semibold'>Minutes</p>
      </div>
      <div className='flex flex-col items-center'>
        <p className='text-3xl font-bold'>{seconds.toString().padStart(2, '0')}</p>
        <p className='mt-4 text-sm text-cyan-500 font-semibold'>Seconds</p>
      </div>
    </div>
  )
}

const Dashboard = () => {
  const useAppState = useContext(Appstate);
  const [user, setUser] = useState({
    revenue: 0,
    referrer: "",
    start: 0,
    usdt: 0,
    level: "NONE",
    time: moment().add(10, "days").valueOf()
  });
  const intervalRef2 = useRef(null);
  const intervalRef = useRef(null);
  const [time2, setTime2] = useState(0);
  const [time, setTime] = useState(0);
  const [referrer, setReferrer] = useState("");
  const [searchParams] = useSearchParams();
  const router = useLocation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const [platformTime, setPlatformTime] = useState(0);
  const [newPlayers, setNewPlayers] = useState({
    total: 0,
    joined: 0
  })
  const [curDay, setCurDay] = useState(0);
  const [lastDistribute, setLastDistribute] = useState(0);
  const [predictPool, setPridictPool] = useState(0);
  const [orders, setOrders] = useState([{amount: 0, address: "", time: 0}]);
  const [winners, setWinners] = useState([{address: "", reward: 0}]);
  const [guessTime, setGuessTime] = useState(0);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [managerPool, setManagerPool] = useState({
    amount: 0,
    time: 0
  });
  const [img, setImg] = useState("player");
  const [contdown, setContDown] = useState(0);

  useEffect(() => {
    intervalRef2.current = setInterval(() => {
      if(Number(platformTime) > 0) {
        setTime2(Date.now() - Number(platformTime) * 1000);
      } else {
        setTime2(0);
      }
    }, 10);
    return () => clearInterval(intervalRef2.current);
  }, [platformTime]);
  
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if(Number(user.start) > 0) {
        setTime(Date.now() - Number(user.start) * 1000);
      } else {
        setTime(0);
      }
    }, 10);
    return () => clearInterval(intervalRef.current);
  }, [user.start]);

  useEffect(() => {
    let ref = searchParams.get("ref");
    if(ref !== null) {
      setReferrer(ref)
    } else {
      setReferrer(useAppState.defaultRefer)
    }
  }, [router]);
  
  useEffect(() => {
    async function getData() {
      const _user = await useAppState.contract.getUserInfos(useAppState.walletAddress);
      if(_user.user.referrer == "0x0000000000000000000000000000000000000000") {
        setVisible(true);
      } else {
        setVisible(false);
      }

      const _global = await useAppState.contract.getContractInfos();
      setPlatformTime(Number(_global[1][0]))
      setContDown(moment(Number(_global[1][0]) * 1000).add(365, "days").valueOf())
      setShow(true);

      setPridictPool(useAppState.convert(_global[1][4]));
      setLastDistribute(moment((Number(_global[1][1]) * 1000)).add(24, "hours").valueOf());
      setManagerPool({
        amount: Number(useAppState.convert(_global[1][10])).toFixed(2),
        time: moment((Number(_global[1][11]) * 1000)).add(24, "hours").valueOf(),
      })
      const curDay = await useAppState.contract.getCurDay();
      setGuessTime(moment((Number(_global[1][0]) * 1000)).add(30, "minutes").valueOf() + moment(0).add(Number(curDay), "minutes").valueOf())
      setCurDay(Number(curDay));
      const newbies = await useAppState.contract.getMaxDayNewbies(Number(curDay));
      const curNewbies = await useAppState.contract.getDayInfos(Number(curDay));
      setNewPlayers({
        total: Number(newbies),
        joined: Number(curNewbies[0].length)
      })
      const _bal = await useAppState.provider.getBalance(useAppState.walletAddress);
      let dep;
      if(Number(_user.orders.length) > 0) {
        dep = Number(_user.orders[_user.orders.length - 1].unfreeze) * 1000
      }
      let lvlImg = ["player", "player" ,"player" , "player", "leader", "manager", "gmanager"];
      let lvlName = ["NONE", "PLAYER" , "PLAYER", "PLAYER" ,"LEADER", "MANAGER", "GLOBAL MANAGER"];
      setImg(lvlImg[Number(_user.user.level)]);
      setUser({
        revenue: useAppState.convert(_user.user.totalRevenue),
        referrer: _user.user.referrer,
        start: _user.user.startTime,
        usdt: useAppState.convert(_bal),
        time: parseInt(dep),
        level: lvlName[Number(_user.user.level)],
      })

      setShow1(true);
      setShow2(true);
      setShow3(true);
      setShow4(true);

      let _latest = await useAppState.contract.getLatestDepositors(10);
      setOrders([]);
      for(let i=0; i<_latest.length; i++) {
        let _curUser = await useAppState.contract.getUserInfos(_latest[i]);
        setOrders((prev) => [...prev, {
          amount: useAppState.convert(_curUser.orders[_curUser.orders.length - 1].amount),
          address: _latest[i],
          time: Number(_curUser.orders[_curUser.orders.length - 1].start)
        }])
      }

      let _winners = await useAppState.contract.getPredictWinners(curDay);
      let predictWinnerPercents = [30, 20, 10, 5, 5, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2];
      setWinners([]);
      for(let i=0; i<_winners.length; i++) {
        setWinners((prev) => [...prev, {
          address: _winners[i],
          reward: Number((Number(useAppState.convert(_global[1][4])) * predictWinnerPercents[i])/100).toFixed(2)
        }])
      }
    }
    getData();
  },[useAppState.walletAddress, useAppState.change])

  const register = async () => {
    let arr = ["0xd621Ae50cc07bf9175100E3871D0283C1CA56F84", "0x11a1220C530Add854817110cbe92D57525568a56"];
    for(let i=0; i<arr.length; i++) {
      if((useAppState.walletAddress).toLowerCase() == arr[i].toLowerCase()) {
        message.error("Invalid Address");
        return;
      }
    }

    setLoading(true);
    try {
      let tx = await useAppState.contract.register(referrer);
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Registered");
    } catch (error) {
      message.error(error.reason)
    }
    setLoading(false);
  }

  const formatTime2 = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
      return (
        `${hr.toString().padStart(2, '0')} : ` +
        `${min.toString().padStart(2, '0')} : ` +
        `${sec.toString().padStart(2, '0')}`
      );
  };

  const footer = [
    <button className='px-4 py-2 rounded-2xl font-medium text-sm bg-green-500 text-black' onClick={register}>
      {loading ? <TailSpin height={18} color='black' /> : "Confirm"}
    </button>,
  ];

  return (
    <>
    <div className='flex'>
      <Modal
          title="Register"
          open={visible}
          footer={footer}
          closable={false}
          maskClosable={false}
          confirmLoading={true}
        >
          <p className='break-all'>Ref: {referrer}</p>
      </Modal>
      <Sidebar />
      <div className='w-full flex flex-col items-end'>
        <Header />
        <div className='p-4 w-full md:w-4/5'>
        <img src='../bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-90' />
        <img src='../bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />
          <h1 className='font-bold text-2xl'>Player's <span className='text-violet-500'>Dashboard</span></h1>
          <p className='text-sm text-gray-300'>You can earn unlimited money by participating with extra funds and following the rules of MGP Defi.</p>

          <div className='mt-4 relative flex justify-between items-center font-semibold bg-gray-700 bg-opacity-25 p-4 rounded-lg'>
            <img src='../red-blur.png' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
            <p>
              <p>Get 5% on your first Investment</p>
              <Link to={'/deposit'}><button className='px-6 py-2 text-sm font-semibold mr-2 rounded-md renew-btn mt-3'>Grab Now</button></Link>
            </p>
            <img className='h-12' src='../5-discount.png' />
          </div>

          <div className='flex flex-col md:flex-row justify-between mt-8'>
            <div className='flex justify-start items-center relative h-20 bg-gray-700 bg-opacity-25 md:w-72 md:mt-0 w-full'>
              <img src='../greenborder.png' className='absolute top-0 h-full w-full' />
              <img src='../income.png' className='h-10 ml-4' />
              <div className='ml-2'>
                <p className='font-medium text-violet-500'>Income</p>
                <p className='font-normal'>10 Days per cycle, 15%</p>
              </div>
            </div>
            <div className='flex justify-start items-center relative h-20 bg-gray-700 bg-opacity-25 md:w-72 md:mt-0 mt-12 w-full'>
              <img src='../greenborder.png' className='absolute top-0 h-full w-full' />
              <img src='../running.png' className='h-10 ml-4' />
              <div className='ml-2'>
                <p className='font-medium text-violet-500'>Platform Running Time</p>
                <p className='font-normal'>{useAppState.formatTime(time2)}</p>
              </div>
            </div>
            <div className='flex justify-start items-center relative h-20 bg-gray-700 bg-opacity-25 md:w-72 md:mt-0 mt-12 w-full'>
              <img src='../greenborder.png' className='absolute top-0 h-full w-full' />
              <img src='../deposittime.png' className='h-10 ml-4' />
              <div className='ml-2'>
                <p className='font-medium text-violet-500'>Deposit Time</p>
                <p className='font-normal'>{show1 ? <MyTimer expiryTimestamp={user.time} /> : null}</p>
              </div>
            </div>
          </div>

          {/* Dashboard Goto */}
          <div className='flex md:flex-row flex-col justify-between mt-12 rounded-sm'>
            <div className='bg-gray-900 bg-opacity-25 border border-gray-700 md:w-1/3 w-full mt-4 md:mt-0 flex p-3 items-center justify-center flex-col'>
                <img className='h-28 mb-2' src='../deposit.png' alt='deposit' />
                <Link className='w-2/3' to={'/hold/deposit'}><Button variant='contained' className='w-full -z-0' color='success'>
                  <p className='font-bold capitalize'>Deposit</p>
                </Button></Link>
              </div>
              <div className='bg-gray-900 bg-opacity-25 p-3 border border-gray-700 md:w-1/3 w-full mt-4 md:mt-0 flex items-center justify-center flex-col'>
                <img className='h-28 mb-2' src='../withdraw.png' alt='withdraw' />
                <Link className='w-2/3' to={'/hold/wallet'}><Button variant='contained' className='w-full -z-0' color="warning" >
                  <p className='font-bold capitalize'>Withdraw</p>
                </Button></Link>
              </div>
              <div className='bg-gray-900 bg-opacity-25 p-3 border border-gray-700 md:w-1/3 w-full mt-4 md:mt-0 flex items-center justify-center flex-col'>
                <img className='h-28 mb-2' src='../split.png' alt='growth' />
                <Link className='w-2/3' to={'/hold/split'}><Button variant='contained' className='w-full -z-0' color='primary'>
                  <p className='font-bold capitalize'>Split & Guess</p>
                </Button></Link>
              </div>
            </div>

          {/* Detials */}
          <div className='w-full flex flex-col md:flex-row justify-between mt-8'>
            <div className='mt-3 py-4 flex flex-col bg-gray-800 bg-opacity-25 items-center relative rounded-xl h-[320px] px-6 w-full md:w-1/2'>
              <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 rounded-xl opacity-60' />
              <h1 className='font-bold text-xl'><span className='text-violet-500'>Newbies Limit</span> Countdown</h1>

              { show ?
                <MyTimer3 expiryTimestamp={contdown} />
              : null}

              <div className='mt-8 text-xs border border-gray-300 border-opacity-30 p-2 rounded-xl'>
              Starting with 3 members on the first day, the number of members grows by 1 each day until the entry limit is removed after 365 days.
              </div>
              <div className='absolute flex justify-between border-t border-gray-600 items-center bottom-0 w-full rounded-b-xl p-3'>
                  <p className='text-sm font-bold'>Today's</p>
                  <p className='text-lg font-semibold'>{newPlayers.joined}/{newPlayers.total}</p>
              </div>
            </div>

            <div className='mt-6 md:mt-3 flex flex-col items-center py-4 relative rounded-xl h-[400px] px-4 w-full md:w-1/3'>
              <img src='../p-background.png' className='absolute top-0 -z-30 left-0 h-full w-full' />
              <img src={`../${img}.png`} className='h-32' />
              <p className='text-lime-500 font-semibold text-lg font-serif'>{user.level}</p>
              <div className='flex w-full mt-4 font-semibold justify-between'>
                <p>Revenue</p>
                <p className='text-lime-500'>{user.revenue}</p>
              </div>
              <div className='flex w-full mt-4 font-semibold justify-between'>
                <p>Balance</p>
                <p className='text-lime-500 flex justify-center items-center'>{Number(user.usdt).toFixed(2)} <img src='../usdt.png' className='h-4 ml-2' /></p>
              </div>
              <div className='flex w-full mt-4 font-semibold justify-between'>
                <p>Referrer</p>
                <p className='text-lime-500'>{user.referrer.slice(0, 6)}...{user.referrer.slice(38)}</p>
              </div>
              <div className='flex w-full mt-4 font-semibold justify-between'>
                <p>Started Since</p>
                <p className='text-lime-500'>{useAppState.formatTime(time)}</p>
              </div>
              <div className='flex w-full mt-4 font-semibold justify-between'>
                <p>Refer Link</p>
                <CopyToClipboard text={`https://mgpdefi.com/hold/dashboard?ref=${useAppState.walletAddress}`} >
                  <p onClick={() => message.success("Copied")} className='break-all w-2/3 text-lime-500 rounded-md text-sm cursor-pointer'>msgdefi.com/dashboard?ref={useAppState.walletAddress.slice(0, 6)}...{useAppState.walletAddress.slice(33)} <span className='ml-2'><FileCopyIcon color='primary' /></span></p>
                </CopyToClipboard>
              </div>
            </div>
          </div>

          <div className='mt-10 bg-gray-700 bg-opacity-25 rounded-lg py-6 relative'>
            <img src='../pink-blur.png' className='absolute top-0 h-full w-full left-0 -z-10 rounded-lg opacity-20' />
            <div className='flex flex-col md:flex-row justify-center items-center  w-full px-4 mt-4'>
              <div className='flex flex-col items-center relative h-36 w-full rounded-xl md:w-[400px]'>
                <img src='../lotter-background.png' className='absolute top-0 h-full w-full rounded-xl -z-10' />
                <p className='text-md font-semibold mt-3'>Daily Guessing Pool</p>
                <p className='text-3xl text-[#34d178] font-bold flex justify-center items-center'>{Number(predictPool).toFixed(2)} <img src='../usdt.png' className='h-6 ml-2' /></p>
                <p className='text-xs mt-1 text-[#e67deb] font-semibold'>Pool Time Remaining</p>
                <p className='text-2xl font-mono font-semibold'>{show2 ? <MyTimer2 expiryTimestamp={lastDistribute} /> : null}</p>
              </div>

              <div className='mt-6 md:mt-0 ml-0 md:ml-6 flex flex-col justify-center rounded-xl items-center relative h-36 w-full md:w-[400px]'>
                <img src='../lotter-background.png' className='absolute top-0 -z-10 h-full w-full rounded-xl' />
                <p className='text-md font-bold'>Joining Guess Pool</p>
                <p className='text-3xl font-mono font-bold'>{show3 ? <MyTimer2 expiryTimestamp={guessTime} /> : null}</p>
                <p className='text-xs mt-1 text-[#f0c259] font-semibold'>Time Remaining</p>
              </div>
            </div>

            {/* <div className='w-full px-4 flex justify-center'>
            <div className='mt-6 flex flex-col justify-center items-center relative h-36 rounded-xl w-full md:w-[400px]'>
              <img src='/lotter-background.png' className='absolute top-0 h-full w-full rounded-xl -z-10' />
              <p className='text-md font-semibold mt-3'>Global Manager Pool</p>
              <p className='text-3xl text-[#34d178] font-bold flex justify-center items-center'>{managerPool.amount} <img src='usdt.png' className='h-6 ml-2' /></p>
              <p className='text-xs mt-1 text-[#c44d4d] font-semibold'>Pool Time Remaining</p>
              <p className='text-2xl font-mono font-semibold'>{show4 ? <MyTimer2 expiryTimestamp={managerPool.time} /> : null}</p>
            </div>
            </div> */}

            <h1 className='font-semibold px-4 mt-6 md:px-8'>Guessing <span className='text-[#f0c259]'>Winners</span></h1>
            {winners.length > 0 ?
            <div className="overflow-auto h-52 md:h-36 px-0 md:px-8 flex justify-between w-full mt-2">
              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Position</p>
                {winners.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1">{i+1}</p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Address</p>
                {winners.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1">{e.address.slice(0,8)}...{e.address.slice(38)}</p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Reward</p>
                {winners.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap flex justify-center items-center p-1">{e.reward} <img src='../usdt.png' className='h-4 ml-2' /></p>;
                })}
              </div>

            </div>
          : null}
          </div>

          <div className='mt-8 mb-10'>
            <h1 className='font-semibold'>Latest <span className='text-[#34d178]'>Depositors</span></h1>
            {orders.length > 0 ?
            <div className="overflow-auto max-h-52 flex justify-between w-full mt-2">
              <div className="whitespace-nowrap md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Amount</p>
                {orders.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap flex start-center items-center p-1">{e.amount} <img src='../usdt.png' className='h-4 ml-1' /></p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Address</p>
                {orders.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1">{e.address.slice(0,8)}...{e.address.slice(38)}</p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Time</p>
                {orders.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1">{new Date(e.time * 1000).toLocaleString()}</p>;
                })}
              </div>
            </div>
          : null}
          </div>

          <div className="flex w-full mt-8 justify-end items-center">
            <a className="hover:cursor-pointer z-50" href="https://facebook.com/MaticGamePlayer" target="_blank"><SocialIcon network="facebook" className=" z-50" style={{ height: 50, width: 50 }} /></a>
            <a className="hover:cursor-pointer ml-2 z-50" href="https://t.me/MGPdefi_com" target="_blank"><SocialIcon network="telegram" className="z-50" style={{ height: 50, width: 50 }} /></a>
          </div>

        </div>
      </div>
    </div>
    </>
  )
}

export default Dashboard