import { useContext, useEffect, useState } from 'react'
import {Sidebar, Header} from './index'
import { Appstate } from '../App'
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { FileCopy } from '@mui/icons-material';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import GroupsIcon from '@mui/icons-material/Groups';
import { SocialIcon } from 'react-social-icons';

const Teams = () => {
  const useAppState = useContext(Appstate);
  const [data, setData] = useState({
    total: 0,
    A: 0,
    B: 0,
    team: 0,
    self: 0
  })

  useEffect(() => {
    async function getData() {
      let _data = await useAppState.contract.getTeamDeposit(useAppState.walletAddress);
      let _user = await useAppState.contract.getUserInfos(useAppState.walletAddress);
      setData({
        total: Number(useAppState.convert(_data[2])),
        A: Number(useAppState.convert(_data[0])),
        B: Number(useAppState.convert(_data[1])),
        team: Number(_user.user.teamNum),
        self: Number(useAppState.convert(_user.user.maxDeposit))
      })
    }
    getData();
  },[useAppState.walletAddress, useAppState.change])

  return (
    <>
    <div className='flex'>
      <Sidebar />
      <div className='w-full flex flex-col items-end'>
        <Header />
        <div className='p-4 w-full md:w-4/5 min-h-screen'>
        <img src='../bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-90' />
        <img src='../bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />
        
          <h1 className='font-bold text-2xl mt-3'>My <span className='text-violet-500'>Teams</span></h1>

          {/* Teams Details */}
          <div className='flex flex-col md:flex-row mt-8 px-4'>
            <div className='flex justify-between w-full md:w-1/2 px-4 bg-gray-800 bg-opacity-30 relative py-4 rounded-lg'>
              <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
              <div className='flex flex-col'>
                <span className='font-semibold'>Total Team Performace</span>
                <span className='font-semibold text-lg flex items-center'>{Number(data.total).toFixed(2)} <img src="/usdt.png" className="h-4 ml-2" /></span>
              </div>
              <p className='text-green-500'><Diversity2Icon fontSize='large' /></p>
            </div>
            <div className='flex ml-0 md:ml-4 mt-4 md:mt-0 justify-between w-full md:w-1/2 px-4 bg-gray-800 bg-opacity-30 relative py-4 rounded-lg'>
              <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
              <div className='flex flex-col'>
                <span className='font-semibold'>Team A Performance</span>
                <span className='font-semibold text-lg flex items-center'>{Number(data.A).toFixed(2)} <img src="/usdt.png" className="h-4 ml-2" /></span>
              </div>
              <p className='text-green-500'><CallSplitIcon fontSize='large' className='rotate-180' /></p>
            </div>
          </div>
          <div className='flex flex-col-reverse md:flex-row mt-4 px-4'>
            <div className='flex justify-between mt-4 md:mt-0 w-full md:w-1/2 px-4 bg-gray-800 bg-opacity-30 relative py-4 rounded-lg'>
              <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
              <div className='flex flex-col'>
                <span className='font-semibold'>Team members</span>
                <span className='font-semibold text-lg'>{data.team}</span>
              </div>
              <p className='text-green-500'><GroupsIcon fontSize='large' /></p>
            </div>
            <div className='flex ml-0 md:ml-4 justify-between w-full md:w-1/2 px-4 bg-gray-800 bg-opacity-30 relative py-4 rounded-lg'>
              <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
              <div className='flex flex-col'>
                <span className='font-semibold'>Team B Performance</span>
                <span className='font-semibold text-lg flex items-center'>{Number(data.B).toFixed(2)} <img src="/usdt.png" className="h-4 ml-2" /></span>
              </div>
              <p className='text-green-500'><AltRouteIcon fontSize='large' className='rotate-180'  /></p>
            </div>
          </div>

          <h1 className='font-bold text-2xl w-full text-center text-green-500 mt-12'>Levels</h1>

          <div className='flex flex-col md:flex-row items-center justify-center px-1 py-4 md:px-4 mt-8'>
            <div className='flex flex-col items-center'>
              <img src='../gmanager.png' className='h-24' />
              <h1 className='font-mono text-lg font-bold text-green-500'>Global Manager</h1>
            </div>
            <div className='ml-0 md:ml-24 w-full md:w-1/3'>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Deposit: <span className='text-white'>{data.self}/3000</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.self * 100)/3000)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Team A: <span className='text-white'>{data.A}/50,000</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.A * 100)/50000)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Team B: <span className='text-white'>{data.B}/50,000</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.B * 100)/50000)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Team Size: <span className='text-white'>{data.team}/500</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.team * 100)/500)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
            </div>
          </div>
          <div className='mt-4 w-full flex justify-center'><div className='w-full md:w-4/5 border border-gray-400 border-opacity-30'></div></div>


          <div className='flex flex-col md:flex-row items-center justify-center px-1 py-4 md:px-4 mt-8'>
            <div className='flex flex-col items-center'>
              <img src='../manager.png' className='h-24' />
              <h1 className='font-mono text-lg font-bold text-green-500'>Manager</h1>
            </div>
            <div className='ml-0 md:ml-24 w-full md:w-1/3'>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Deposit: <span className='text-white'>{data.self}/3000</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.self * 100)/3000)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Team A: <span className='text-white'>{data.A}/20,000</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.A * 100)/20000)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Team B: <span className='text-white'>{data.B}/20,000</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.B * 100)/20000)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Team Size: <span className='text-white'>{data.team}/200</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.team * 100)/200)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
            </div>
          </div>
          <div className='mt-4 w-full flex justify-center'><div className='w-full md:w-4/5 border border-gray-400 border-opacity-30'></div></div>
          
          <div className='flex flex-col md:flex-row items-center justify-center px-1 py-4 md:px-4 mt-8'>
            <div className='flex flex-col items-center'>
              <img src='../leader.png' className='h-24' />
              <h1 className='font-mono text-lg font-bold text-green-500'>Leader</h1>
            </div>
            <div className='ml-0 md:ml-24 w-full md:w-1/3'>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Deposit: <span className='text-white'>{data.self}/2000</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.self * 100)/2000)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Team A: <span className='text-white'>{data.A}/10,000</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.A * 100)/10000)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Team B: <span className='text-white'>{data.B}/10,000</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.B * 100)/10000)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Team Size: <span className='text-white'>{data.team}/50</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.team * 100)/50)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
            </div>
          </div>
          <div className='mt-4 w-full flex justify-center'><div className='w-full md:w-4/5 border border-gray-400 border-opacity-30'></div></div>

          <div className='flex flex-col md:flex-row justify-center px-1 py-4 md:px-4 mt-8'>
            <div className='flex flex-col items-center'>
              <img src='../player.png' className='h-24' />
              <h1 className='font-mono text-lg font-bold text-green-500'>Player</h1>
            </div>
            <div className='ml-0 md:ml-24 w-full md:w-1/3'>
              <p className='mt-3 flex flex-col w-full'>
                <span className='text-sm font-normal text-yellow-500'>Deposit: <span className='text-white'>{data.self}/50</span></span>
                <span className='text-sm font-semibold mt-1 w-full bg-gray-300 h-[3px] rounded-3xl'><p style={{width: `${parseInt((data.self * 100)/50)}%`}} className={`h-full max-w-full rounded-3xl bg-teal-500`}></p></span>
              </p>
            </div>
          </div>

          <div className="flex w-full justify-end mt-8 items-center">
            <a className="hover:cursor-pointer z-50" href="https://facebook.com/MaticGamePlayer" target="_blank"><SocialIcon network="facebook" className=" z-50" style={{ height: 50, width: 50 }} /></a>
            <a className="hover:cursor-pointer ml-2 z-50" href="https://t.me/MGPdefi_com" target="_blank"><SocialIcon network="telegram" className="z-50" style={{ height: 50, width: 50 }} /></a>
          </div>

        </div>
      </div>
    </div>
    </>
  )
}

export default Teams