import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { TailSpin } from "react-loader-spinner";
import { Appstate } from "../App";
import { message } from "antd";
import { ethers } from "ethers";

const Fee = () => {
  const useAppState = useContext(Appstate);
  const [addr, setAddr] = useState("");
  const [addr2, setAddr2] = useState("");
  const [amt2, setAmt2] = useState("");
  const [load, setLoad] = useState(0);
  const [load2, setLoad2] = useState(false);
  const [bal, setBal] = useState(0);
  const [bal2, setBal2] = useState(0);
  const [load3, setLoad3] = useState({1: false, 2: false});
  const [place, setPlace] = useState("");

  const transfer2 = async () => {
    setLoad(true);
    try {
      let tx = await useAppState.insurance.transferFunds(
        addr, ethers.utils.parseEther(amt2)
      );
      await tx.wait();
      message.success("Sucessfully Transferred");
    } catch (error) {
      message.error(error.reason);
    }
    setLoad(false);
  };

  const change = async () => {
    setLoad2(true);
    try {
      let tx = await useAppState.feeContract.setAddr(
        addr2, place
      );
      await tx.wait();
      message.success("Sucessfully Changed");
    } catch (error) {
      message.error(error.reason);
    }
    setLoad2(false);
  };

  const distribute = async (type) => {
    setLoad3({...load3, [type]: true});
    try {
      let tx = await useAppState.feeContract.distribute();
      await tx.wait();

      message.success("Sucessfully Distributed");
    } catch (error) {
      message.error(error.reason);
    }
    setLoad3({...load3, [type]: false});
  }

  useEffect(() => {
    async function getData() {
      let _bal2 = await useAppState.insurance.getBal();
      setBal(useAppState.convert(_bal2));
      let _bal = await useAppState.feeContract.getBal();
      setBal2(useAppState.convert(_bal))
    }
    getData();
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full flex flex-col items-end">
        <img src='bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-100' />
        <img src='bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />
        <Header />

        {useAppState.isAdmin3 ?
        <div className="w-full p-4 md:w-4/5 relative">
          <div className="mt-4 rounded-lg bg-gray-800 bg-opacity-25">
            <p className="flex justify-between py-2 px-3 bg-[#560c8a] rounded-t-lg">
              <span className="text-sm font-semibold font-mono">
                {Number(bal).toFixed(2)}
              </span>
              <span className="text-sm">Insurance Balance</span>
            </p>
            <div className="p-4">
              <h1 className="font-bold text-lg">Transfer</h1>
              <p
                className="mt-2 text-sm font-medium"
              >
                Receiver Address
              </p>
              <div className="flex">
                <input
                  value={addr}
                  onChange={(e) => setAddr(e.target.value)}
                  className="bg-white text-gray-600 text-sm p-2 rounded-md mt-1 mr-3 outline-gray-500 outline-1 w-full"
                  placeholder="Enter Address"
                />
              </div>

              <div className="flex mt-4">
                <input
                  className="bg-white text-gray-600 text-sm p-2 rounded-md mt-1 mr-3 outline-gray-500 outline-1"
                  placeholder="Enter Amount"
                  value={amt2}
                  onChange={(e) => setAmt2(e.target.value)}
                />
                <p className="flex items-center">
                  <img src="/usdt.png" className="h-4 mr-1" /> Matic
                </p>
              </div>
            </div>
            <div className="w-full flex justify-between p-4 pt-0">
              <span></span>
              <button
                onClick={transfer2}
                className="px-6 py-2 text-sm font-semibold mr-2 rounded-md renew-btn"
              >
                {load ? <TailSpin height={14} color="white" /> : "Transfer"}
              </button>
            </div>
          </div>

          {/* Fee Addr Change */}
          <div className="mt-4 rounded-lg bg-gray-800 bg-opacity-25">
            <p className="flex justify-between py-2 px-3 bg-[#560c8a] rounded-t-lg">
              <span className="text-sm font-semibold font-mono">
                {Number(bal2).toFixed(2)}
              </span>
              <span className="text-sm">Fee Balance</span>
            </p>
            <div className="p-4">
              <h1 className="font-bold text-lg">Change Address</h1>
              <p
                className="mt-2 text-sm font-medium"
              >
                New Address
              </p>
              <div className="flex">
                <input
                  value={addr2}
                  onChange={(e) => setAddr2(e.target.value)}
                  className="bg-white text-gray-600 text-sm p-2 rounded-md mt-1 mr-3 outline-gray-500 outline-1 w-full"
                  placeholder="Enter Address"
                />
              </div>

              <div className="flex mt-4">
                <input
                  className="bg-white text-gray-600 text-sm p-2 rounded-md mt-1 mr-3 outline-gray-500 outline-1"
                  placeholder="0 for 1st & 1 for 2nd"
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full flex justify-between p-4 pt-0">
              <span></span>
              <button
                onClick={change}
                className="px-6 py-2 text-sm font-semibold mr-2 rounded-md renew-btn"
              >
                {load2 ? <TailSpin height={14} color="white" /> : "Transfer"}
              </button>
            </div>
          </div>


          <p className="flex justify-between mt-6 py-2 px-3 bg-[#560c8a] rounded-t-lg">
              <span className="text-sm font-semibold font-mono">
                {Number(bal2).toFixed(2)} Matic
              </span>
              <span className="text-sm">Fee Balance</span>
            </p>

          <div className='mt-2 w-full flex md:flex-row flex-col justify-center'>
            <button onClick={() => distribute(1)} className='px-16 py-4 flex justify-center items-center w-full text-sm font-semibold rounded-md renew-btn'>{load3[1] ? <TailSpin height={15} color='white' /> : "Distribute Fee"}</button>
          </div>
        </div>: null}
      </div>
    </div>
  );
};

export default Fee;
