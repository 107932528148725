import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { TailSpin } from "react-loader-spinner";
import { Appstate } from "../App";
import { message } from "antd";
import { ethers } from "ethers";

const Admin = () => {
  const useAppState = useContext(Appstate);
  const [bal, setBal] = useState(0);
  const [load3, setLoad3] = useState({1: false, 2: false});

  const distribute = async (type) => {
    setLoad3({...load3, [type]: true});
    try {
      if(type==1) {
        let tx = await useAppState.contract.distributeGlobalManagerPool();
        await tx.wait();
      } else {
        let tx = await useAppState.contract.distributePredictPool();
        await tx.wait();
      }

      message.success("Sucessfully Distributed");
    } catch (error) {
      message.error(error.reason);
    }
    setLoad3({...load3, [type]: false});
  }

  useEffect(() => {
    async function getData() {
      let _bal2 = await useAppState.insurance.getBal();
      setBal(useAppState.convert(_bal2));
    }
    getData();
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full flex flex-col items-end">
        <img src='bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-100' />
        <img src='bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />
        <Header />

        {useAppState.isAdmin ?
        <div className="w-full p-4 md:w-4/5 relative">
            <p className="flex justify-between py-2 px-3 bg-[#560c8a] rounded-t-lg">
              <span className="text-sm font-semibold font-mono">
                {Number(bal).toFixed(2)} Matic
              </span>
              <span className="text-sm">Insurance Balance</span>
            </p>
          <div className='mt-6 w-full flex md:flex-row flex-col justify-center'>
            <button onClick={() => distribute(1)} className='px-16 py-4 flex justify-center items-center w-full text-sm font-semibold rounded-md renew-btn'>{load3[1] ? <TailSpin height={15} color='white' /> : "Distribute Global Manager Pool"}</button>
            <button onClick={() => distribute(2)} className='ml-0 md:ml-4 md:mt-0 mt-4 px-16 flex justify-center items-center w-full py-4 text-sm font-semibold rounded-md bg-[#0ddc85] text-black'>{load3[2] ? <TailSpin height={15} color='white' /> : "Distribute Predict Pool"}</button>
          </div>
        </div>: null}
      </div>
    </div>
  );
};

export default Admin;
