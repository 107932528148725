import { useContext, useEffect, useState } from "react"
import { Sidebar, Header } from "./index"
import { Appstate } from "../App"
import { message } from "antd";
import { TailSpin } from "react-loader-spinner";
import { SocialIcon } from "react-social-icons";

const Wallet = () => {
  const useAppState = useContext(Appstate);
  const [data, setData] = useState({
    capitals: 0,
    cycle: 0,
    lvl1to4: 0,
    lvl5to20: 0,
    lvl5to20Freezed: 0,
    bonus: 0,
    bonusFreezed: 0,
    guessing: 0,
    pool: 0,
    total: 0
  })
  const [loading, setLoading] = useState(false);

  const withdraw = async () => {
    setLoading(true);
    try {
      let tx = await useAppState.contract.withdraw();
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Withdrawn");
    } catch(error) {
      message.error(error.reason)
    }
    setLoading(false);
  }

  useEffect(() => {
    async function req() {
      let _user = await useAppState.contract.getUserInfos(useAppState.walletAddress);
      console.log(_user.reward)
      let _final = {
        capitals: useAppState.convert(_user.reward.capitals),
        cycle: useAppState.convert(_user.reward.statics),
        lvl1to4: useAppState.convert(_user.reward.invited),
        lvl5to20: useAppState.convert(_user.reward.l5Released),
        lvl5to20Freezed: useAppState.convert(_user.reward.l5Freezed),
        bonus: useAppState.convert(_user.reward.bonusReleased),
        bonusFreezed: useAppState.convert(_user.reward.bonusFreezed),
        guessing: useAppState.convert(_user.reward.predictWin),
        pool: useAppState.convert(_user.reward.pool),
        total: 0
      }
      _final.total += Number(_final.capitals) + Number(_final.cycle) + Number(_final.lvl1to4) + Number(_final.lvl5to20) + Number(_final.guessing) + Number(_final.bonus) + Number(_final.pool);
      setData(_final);
    }
    req();
  },[useAppState.walletAddress, useAppState.change])

  return (
    <>
    <div className='flex'>
      <Sidebar />
      <div className='w-full flex flex-col items-end'>
        <Header />
        <div className='p-4 w-full md:w-4/5 relative mb-4'>
        <img src='/bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-100' />
        <img src='/bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />

          <div className='flex flex-col md:flex-row w-full mt-3'>
              <div className='w-full md:w-3/5 mt-4 md:mt-0'>
                  <p className='flex relative flex-row-reverse justify-between bg-gray-700 bg-opacity-25 items-end px-4 py-2'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <span className='text-lg font-semibold flex items-center'>{data.capitals} <img src="/usdt.png" className="h-4 ml-2" /></span>
                    <span>Unlock Principal</span>
                  </p>

                  <p className='flex relative flex-row-reverse justify-between bg-gray-700 bg-opacity-25 items-end px-4 py-2 mt-4'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <span className='text-lg font-semibold flex items-center'>{data.cycle} <img src="/usdt.png" className="h-4 ml-2" /></span>
                    <span>Cycle Reward</span>
                  </p>

                  <p className='flex relative flex-row-reverse justify-between bg-gray-700 bg-opacity-25 items-end px-4 py-2 mt-4'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <span className='text-lg font-semibold flex items-center'>{data.lvl1to4} <img src="/usdt.png" className="h-4 ml-2" /></span>
                    <span>Level 1-4 Reward</span>
                  </p>

                  <div className="relative bg-gray-700 bg-opacity-25">
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data.lvl5to20} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Level 5-20 Released</span>
                    </p>
                    <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.lvl5to20Freezed} Matic</span>
                      <span>Level 5-20 Freezed</span>
                    </p>
                  </div>

                  <div className="relative bg-gray-700 bg-opacity-25">
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data.bonus} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>5% Bonus</span>
                    </p>
                    <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.bonusFreezed} Matic</span>
                      <span>Bonus Freezed</span>
                    </p>
                  </div>

                  <p className='flex relative flex-row-reverse justify-between bg-gray-700 bg-opacity-25 items-end px-4 py-2 mt-4'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <span className='text-lg font-semibold flex items-center'>{data.guessing} <img src="/usdt.png" className="h-4 ml-2" /></span>
                    <span>Guessing Reward</span>
                  </p>

                  <p className='flex relative flex-row-reverse justify-between bg-gray-700 bg-opacity-25 items-end px-4 py-2 mt-4'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <span className='text-lg font-semibold flex items-center'>{data.pool} <img src="/usdt.png" className="h-4 ml-2" /></span>
                    <span>Global Manager Pool</span>
                  </p>
              </div>

              <div className='w-full md:w-2/5 ml-0 md:ml-6 mt-8 md:mt-0'>
                <p className='flex justify-center items-center flex-col px-4 py-3 rounded-l2 w-full relative'>
                  <img src="/greenborder.png" className="absolute w-full top-0 left-0" />
                  <span className='text-sm font-semibold mt-3'><span className="text-green-500">Available</span> Withdrawl</span>
                  <span className="text-xl font-bold flex items-center">{data.total} <img src="/usdt.png" className="h-4 ml-2" /></span>
                </p>
                <div className='mt-8 w-full flex justify-center'>
                  <button className='px-14 py-4 text-sm font-semibold rounded-md renew-btn'>Cancel</button>
                  <button onClick={withdraw} className='ml-4 px-14 py-4 text-sm font-semibold rounded-md bg-[#0ddc85] text-black'>{loading ? <TailSpin height={12} color="black" /> : 'Confirm'}</button>
                </div>
              </div>
            </div>

            <div className="flex w-full justify-end mt-8 items-center">
            <a className="hover:cursor-pointer z-50" href="https://facebook.com/MaticGamePlayer" target="_blank"><SocialIcon network="facebook" className=" z-50" style={{ height: 50, width: 50 }} /></a>
            <a className="hover:cursor-pointer ml-2 z-50" href="https://t.me/MGPdefi_com" target="_blank"><SocialIcon network="telegram" className="z-50" style={{ height: 50, width: 50 }} /></a>
          </div>
          </div>
      </div>
    </div>
    </> 
  ) 
} 

export default Wallet