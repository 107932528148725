import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { TailSpin } from "react-loader-spinner";
import { Appstate } from "../App";
import { message } from "antd";
import { ethers } from "ethers";

const Growth = () => {
  const useAppState = useContext(Appstate);
  const [addr, setAddr] = useState("");
  const [amt, setAmt] = useState("");
  const [load2, setLoad2] = useState(false);
  const [bal, setBal] = useState(0);

  const transfer = async () => {
    setLoad2(true);
    try {
      let tx = await useAppState.contract.transferBySplit(
        addr,
        ethers.utils.parseEther(amt)
      );
      await tx.wait();
      message.success("Sucessfully Transferred");
    } catch (error) {
      message.error(error.reason);
    }
    setLoad2(false);
  };

  useEffect(() => {
    async function getData() {
      let _bal = await useAppState.contract.getContractInfos();
      setBal(useAppState.convert(_bal[1][12]));
    }
    getData();
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full flex flex-col items-end">
        <img src='bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-100' />
        <img src='bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />
        <Header />

        {useAppState.isAdmin2 ?
        <div className="w-full p-4 md:w-4/5 relative">
          <div className="mt-4 rounded-lg bg-gray-800 bg-opacity-25">
            <p className="flex justify-between py-2 px-3 bg-[#560c8a] rounded-t-lg">
              <span className="text-sm font-semibold font-mono">
                {Number(bal).toFixed(2)}
              </span>
              <span className="text-sm">Growth Balance</span>
            </p>
            <div className="p-4">
              <h1 className="font-bold text-lg">Transfer</h1>
              <p
                className="mt-2 text-sm font-medium"
              >
                Receiver Address
              </p>
              <div className="flex">
                <input
                  value={addr}
                  onChange={(e) => setAddr(e.target.value)}
                  className="bg-white text-gray-600 text-sm p-2 rounded-md mt-1 mr-3 outline-gray-500 outline-1 w-full"
                  placeholder="Enter Address"
                />
              </div>

              <div className="flex mt-4">
                <input
                  className="bg-white text-gray-600 text-sm p-2 rounded-md mt-1 mr-3 outline-gray-500 outline-1"
                  placeholder="Enter Amount"
                  value={amt}
                  onChange={(e) => setAmt(e.target.value)}
                />
                <p className="flex items-center">
                  <img src="/usdt.png" className="h-4 mr-1" /> Matic
                </p>
              </div>
            </div>
            <div className="w-full flex justify-between p-4 pt-0">
              <span></span>
              <button
                onClick={transfer}
                className="px-6 py-2 text-sm font-semibold mr-2 rounded-md renew-btn"
              >
                {load2 ? <TailSpin height={14} color="white" /> : "Transfer"}
              </button>
            </div>
          </div>
        </div>: null}
      </div>
    </div>
  );
};

export default Growth;
