import { useContext, useEffect, useState } from "react"
import { Sidebar, Header} from "./index"
import { Appstate } from "../App"
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import { useTimer } from 'react-timer-hook';
import { message } from "antd";
import { ethers } from "ethers";
import { SocialIcon } from "react-social-icons";

function MyTimer2({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
  } = useTimer({ expiryTimestamp});

  return <p><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

const SplitAccount = () => {
  const useAppState = useContext(Appstate);
  const [show, setShow] = useState(false);
  const [lastDistribute, setLastDistribute] = useState(0);
  const [guessTime, setGuessTime] = useState(0);
  const [predictPool, setPridictPool] = useState(0);
  const [bal, setBal] = useState(0);
  const [amt1, setAmt1] = useState("");
  const [amt2, setAmt2] = useState("");
  const [amt3, setAmt3] = useState("");
  const [addr, setAddr] = useState("");
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [load3, setLoad3] = useState(false);
  const [data, setData] = useState([{time: 0, number: 0}]);

  const deposit = async () => {
    const _user = await useAppState.contract.getUserInfos(useAppState.walletAddress);
    if(Number(_user.user.level) > 0) {
      message.warning("Not a newbie");
      return;
    }

    setLoad1(true);
    try {
      let tx = await useAppState.contract.depositBySplit(ethers.utils.parseEther(amt1));
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Deposited")
    } catch (error) {
      message.error(error.reason)
    }
    setLoad1(false);
  }
  
  const transfer = async () => {
    if(Number(amt2)%100 != 0) {
      message.warning("Amount Should be in mutiples of 100");
      return;
    }

    setLoad2(true);
    try {
      let tx = await useAppState.contract.transferBySplit(addr ,ethers.utils.parseEther(amt2));
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Transferred")
    } catch (error) {
      message.error(error.reason)
    }
    setLoad2(false);
  }
  
  const EnterPool = async () => {
    if(Number(amt3)%50 != 0) {
      message.warning("Amount Should be in mutiples of 50");
      return;
    }

    const _user = await useAppState.contract.getUserInfos(useAppState.walletAddress);
    if(_user.user.referrer == "0x0000000000000000000000000000000000000000") {
      message.warning("Register First");
      return;
    }
    
    if(data.length >= 10) {
      message.warning("Max Guess Reached");
      return;  
    }
    
    if(moment().valueOf() >= guessTime) {
      message.warning("Today's Entry Over");
      return;  
    }
    
    let _bal = await useAppState.provider.getBalance(useAppState.walletAddress);
    if(Number(useAppState.convert(_bal)) < 1) {
      message.warning("Insufficient Balance");
      return;    
    }

    setLoad3(true);
    try {
      let tx = await useAppState.contract.predict(ethers.utils.parseEther(amt3), {
        value: ethers.utils.parseEther("1")
      });
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Entered")
    } catch (error) {
      message.error(error.reason)
    }
    setLoad3(false);
  }

  useEffect(() => {
    async function data() {
      const _global = await useAppState.contract.getContractInfos();
      let curDay = await useAppState.contract.getCurDay();
      setPridictPool(useAppState.convert(_global[1][4]));
      setLastDistribute(moment((Number(_global[1][1]) * 1000)).add(24, "hours").valueOf());
      setGuessTime(moment((Number(_global[1][0]) * 1000)).add(30, "minutes").valueOf() + moment(0).add(Number(curDay), "minutes").valueOf())
      setShow(true);

      let _user = await useAppState.contract.getUserInfos(useAppState.walletAddress);
      setBal(useAppState.convert(_user.reward.split))

      let _predicts = await useAppState.contract.getUserDayPredicts(useAppState.walletAddress, Number(curDay));
      setData([]);
      for(let i=0; i<_predicts.length; i++) {
        setData((prev) => [...prev, {time: Number(_predicts[i].time), number: useAppState.convert(_predicts[i].number)}])
      }
    } 
    data();
  },[useAppState.walletAddress, useAppState.change])

  return (
    <>
    <div className='flex'>
      <Sidebar />
      <div className='w-full flex flex-col items-end'>
        <Header />
        <div className='w-full md:w-4/5 relative'>
        <img src='../bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-100' />
        <img src='../bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />

          <div className="flex flex-col md:flex-row items-start p-4 md:justify-between w-full">
            <div className="w-full md:w-2/6">
              <div className="rounded-lg bg-gray-700 bg-opacity-25">
                <p className="flex justify-between py-2 px-3 bg-[#002785] rounded-t-lg">
                  <span className="text-sm font-semibold font-mono flex items-center">{Number(bal).toFixed(2)} <img src="/usdt.png" className="h-4 ml-1" /></span>
                  <span className="text-sm">Split Balance</span>
                </p>
                <div className="p-4">
                  <h1 className="font-bold text-lg">Deposit</h1>
                  <p className="mt-2 text-sm">Amount</p>
                  <div className="flex">
                    <input value={amt1} onChange={(e) => setAmt1(e.target.value)} className="bg-white text-gray-600 text-sm p-2 rounded-md mt-1 mr-3 outline-gray-500 outline-1" placeholder="Enter Amount" />
                    <p className="flex items-center"><img src="/usdt.png" className="h-4 mr-1" /> Matic</p>
                  </div>
                  <p className="mt-1 text-xs">The Ratio of 100</p>
                </div>
                <div className="w-full flex justify-between p-4 pt-0">
                  <span></span>
                  <button onClick={deposit} className='px-6 py-2 text-sm font-semibold mr-2 rounded-md renew-btn'>{load1 ? <TailSpin height={14} color="white" /> : 'Deposit'}</button>
                </div>  
              </div>

              <div className="mt-4 rounded-lg bg-gray-700 bg-opacity-25">
                <p className="flex justify-between py-2 px-3 bg-[#560c8a] rounded-t-lg">
                  <span className="text-sm font-semibold font-mono flex items-center">{Number(bal).toFixed(2)} <img src="/usdt.png" className="h-4 ml-1" /></span>
                  <span className="text-sm">Split Balance</span>
                </p>
                <div className="p-4">
                  <h1 className="font-bold text-lg">Transfer</h1>
                  <p className="mt-2 text-sm font-medium">Receiver Address</p>
                  <div className="flex">
                    <input value={addr} onChange={(e) => setAddr(e.target.value)} className="bg-white text-gray-600 text-sm p-2 rounded-md mt-1 mr-3 outline-gray-500 outline-1 w-full" placeholder="Enter Address" />
                  </div>
                  
                  <p className="mt-2 text-xs">10% Will be burned for each transfer</p>
                  <div className="flex">
                    <input value={amt2} onChange={(e) => setAmt2(e.target.value)} className="bg-white text-gray-600 text-sm p-2 rounded-md mt-1 mr-3 outline-gray-500 outline-1" placeholder="Enter Amount" />
                    <p className="flex items-center"><img src="/usdt.png" className="h-4 mr-1" /> Matic</p>
                  </div>
                  <p className="mt-1 text-xs">The Ratio of 100</p>
                </div>
                <div className="w-full flex justify-between p-4 pt-0">
                  <span></span>
                  <button onClick={transfer} className='px-6 py-2 text-sm font-semibold mr-2 rounded-md renew-btn'>{load2 ? <TailSpin height={14} color="white" /> : 'Transfer'}</button>
                </div>  
              </div>

            </div>

            <div className="w-full pb-4 mt-6 md:mt-0 md:w-4/6 md:ml-4 ml-0 bg-gray-700 bg-opacity-25 rounded-lg">
                  {/* Timer */}
                  <div className='flex flex-col md:flex-row justify-center items-center w-full px-4 mt-4'>
                    <div className='flex flex-col items-center relative h-28 w-full md:w-[400px]'>
                      <img src='../lotter-background.png' className='absolute top-0 h-full w-full -z-10' />
                      <p className='text-md text-sm font-semibold mt-3'>Daily Guessing Pool</p>
                      <p className='text-2xl text-[#34d178] font-bold flex items-center'>{Number(predictPool).toFixed(2)} <img src="/usdt.png" className="h-5 ml-2" /></p>
                      <p className='text-xs text-[#e67deb] font-semibold'>Pool Time Remaining</p>
                      <p className='text-xl font-mono font-semibold'>{show ? <MyTimer2 expiryTimestamp={lastDistribute} /> : null}</p>
                    </div>

                    <div className='mt-6 md:mt-0 ml-0 md:ml-6 flex flex-col justify-center items-center relative h-28 w-full md:w-[400px]'>
                      <img src='../lotter-background.png' className='absolute top-0 h-full w-full -z-10' />
                      <p className='text-md font-bold'>Joining Guess Pool</p>
                      <p className='text-3xl font-mono font-bold'>{show ? <MyTimer2 expiryTimestamp={guessTime} /> : null}</p>
                      <p className='text-xs mt-1 text-[#f0c259] font-semibold'>Time Remaining</p>
                    </div>
                  </div>

                  {/* Percent */}
                  <div className='flex flex-col md:flex-row justify-center items-center w-full px-4 mt-4'>
                    <div className='relative w-full md:w-[400px]'>
                      <p className="text-sm font-semibold">Guess Bonus <span className="text-[#f0c259]">Distribution</span></p>
                      <p className="guessper flex justify-between px-3 py-1 font-semibold rounded-lg mt-2"><span>+30%</span><span>1st <span className="text-[#f0c259]">Place</span></span></p>
                      <p className="guessper flex justify-between px-3 py-1 font-semibold rounded-lg mt-2"><span>+20%</span><span>2nd <span className="text-[#f0c259]">Place</span></span></p>
                      <p className="guessper flex justify-between px-3 py-1 font-semibold rounded-lg mt-2"><span>+10%</span><span>3rd <span className="text-[#f0c259]">Place</span></span></p>
                      <p className="guessper flex justify-between px-3 py-1 font-semibold rounded-lg mt-2"><span>+5%</span><span>4th <span className="text-[#f0c259]">&</span> 5th <span className="text-[#f0c259]">Place</span></span></p>
                      <p className="guessper flex justify-between px-3 py-1 font-semibold rounded-lg mt-2"><span>+2%</span><span>6th <span className="text-[#f0c259]">to</span> 20th <span className="text-[#f0c259]">Place</span></span></p>
                    </div>

                    <div className='mt-6 md:mt-0 ml-0 md:ml-6 h-[238px]  relative w-full md:w-[400px]'>
                      <p className="text-sm mb-2 font-semibold">Guess <span className="text-green-500">History</span></p>
                      <div className="overflow-y-auto h-[160px]">
                      {data.map((e, i) => {
                        return <p className="flex justify-between py-1 border-b border-b-gray-500"><span className="text-sm">{i+1} -</span> <span className="text-green-500 font-semibold">{e.number}</span></p>
                      })}
                      </div>
                      <p className="flex justify-between text-white bg-green-500 px-3 py-2 font-semibold rounded-b-lg mt-2">Bet <span>{data.length}/10</span></p>
                    </div>
                  </div>

                  <p className="mt-4 font-bold text-xl text-center">Guess the Deposit</p>
                  <p className="mt-2 font-semibold text-sm text-[#E67DEB] text-center">Amount of Matic entering in the next 24 hours</p>
                  <div className="px-4">
                    <input value={amt3} onChange={(e) => setAmt3(e.target.value)} className="w-full px-2 py-2 rounded-md text-sm mt-4 outline-gray-500 text-gray-700" placeholder="Enter Amount" />
                  </div>
                  <p className="px-4 text-xs mt-1">Max guess - 10 times, Fee per guess - 1 Matic.</p>

                  <div className="w-full flex justify-between px-4 mt-3 pt-0">
                  <span></span>
                  <button onClick={EnterPool} className='px-6 py-2 text-sm font-semibold mr-2 rounded-md renew-btn'>{load3 ? <TailSpin height={14} color="white" /> : 'Confirm'}</button>
                </div>  
              </div>
          </div>
          <div className="flex w-full justify-end px-4 mb-4 mt-8 items-center">
            <a className="hover:cursor-pointer z-50" href="https://facebook.com/MaticGamePlayer" target="_blank"><SocialIcon network="facebook" className=" z-50" style={{ height: 50, width: 50 }} /></a>
            <a className="hover:cursor-pointer ml-2 z-50" href="https://t.me/MGPdefi_com" target="_blank"><SocialIcon network="telegram" className="z-50" style={{ height: 50, width: 50 }} /></a>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default SplitAccount