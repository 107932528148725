import React, { useContext, useEffect, useState } from "react";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import VerifiedIcon from '@mui/icons-material/Verified';
import BlockIcon from '@mui/icons-material/Block';
import CasinoIcon from '@mui/icons-material/Casino';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import { Link, useNavigate } from "react-router-dom";
import { SocialIcon } from 'react-social-icons';
import { Appstate } from "../App";
import { message } from "antd";

const Homepage = () => {
  const useAppState = useContext(Appstate);
  const navigate = useNavigate();
  const [players, setPlayers] = useState(0);
  const [winners, setWinners] = useState(0);
  const [jackpot, setJackpot] = useState(0);

  useEffect(() => {
    async function getData() {
      let _info = await useAppState.contract.getContractInfos();
      setPlayers(Number(_info[1][2]))
      setWinners(Number(_info[1][6]))
      setJackpot(Number(useAppState.convert(_info[1][5])))
    }
    getData();
  },[])

  const login = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      useAppState.setWalletAddress(accounts[0]);
      navigate('/hold/dashboard');
    } catch (error) {
      message.warning("Connect a web3 wallet"); 
    }
  }

  return (
    <>
      <div className="overflow-x-hidden md:pb-6 pb-24 relative">
        <div className="flex flex-col fixed right-4 bottom-4">
          <a className="hover:cursor-pointer z-50" href="https://facebook.com/MaticGamePlayer" target="_blank"><SocialIcon network="facebook" className=" z-50" style={{ height: 50, width: 50 }} /></a>
          <a className="hover:cursor-pointer z-50" href="https://t.me/MGPdefi_com" target="_blank"><SocialIcon network="telegram" className="mt-2 z-50" style={{ height: 50, width: 50 }} /></a>
        </div>
        <header className="h-[70px] mont shadow-lg flex justify-start items-center">
          <h1 className="font-bold ml-3 text-xl font-mono text-white flex justify-center items-center">
            <img src="MGP.png" className="h-10 mr-2" /> MGP-Defi
          </h1>
        </header>
        <div className="relative flex justify-center items-center md:mt-0 mt-8">
          <img
            className="absolute top-24 md:top-0 -z-10 md:min-w-auto min-w-[900px]"
            src="home-main.png"
          />
          <div className="mt-14 flex w-[90vw] flex-col items-center">
            <h1 className="text-5xl font-extrabold mont"><span className="text-violet-500">Matic</span> Game Player!</h1>
            <p className="w-[90vw] md:w-[50vw] text-lg mt-4 text-center">
              Get your game on to earn rewards, Get 5% additional Matic bonus for new
              players depositing only
            </p>
            {/* <Link to={"/dashboard"}> */}
              <button onClick={login} className="mt-12 bg-[#6415ff] rounded-full font-bold text-lg transition-all hover:bg-[#4801d6] px-6 mont py-3">
                Contribute
              </button>
            {/* </Link> */}
          </div>
        </div>
        <div className="mt-28 flex flex-col md:flex-row justify-around">
          <div className="text-center">
            <p className="text-gray-300">Extra Bonus</p>
            <p className="text-xl font-bold">5%</p>
          </div>
          <div className="text-center mt-6 md:mt-0">
            <p className="text-gray-300">Global Players</p>
            <p className="text-xl font-bold">{players}+</p>
          </div>
          <div className="text-center mt-6 md:mt-0">
            <p className="text-gray-300">Reward Per Day</p>
            <p className="text-xl font-bold">1.5%</p>
          </div>
        </div>

        <div className="mt-24 md:mt-32 w-full flex justify-center p-4 relative">
          <img
            className="absolute top-24 md:top-0 -z-10 md:min-w-auto min-w-[745px]"
            src="home-light.svg"
          />
          <div className="text-center w-full md:w-2/3 mt-6">
            <h1 className="text-lg font-bold">What is MGP?</h1>
            <h1 className="text-3xl font-bold mont">
              Get your game on to earn rewards
            </h1>
            <p className="mont text-gray-300 mt-4">
              MGP is a groundbreaking project that revolutionizes the way users
              earn rewards and grow their investments through its innovative
              smart contract rewarding system. By leveraging the power of
              blockchain technology, MGP Supports provides a transparent and
              secure platform where users can participate in various activities
              and be rewarded. With MGP, users have a unique opportunity to earn
              rewards while actively participating in the growth and development
              of the platform, creating a mutually beneficial ecosystem for all
              participants.
            </p>
          </div>
        </div>

        {/* Get Started */}
        <div className="w-full p-4 mt-32 relative">
          <img
            className="absolute top-24 md:top-0 -z-10 md:min-w-[100vw] opacity-60"
            src="home-light.svg"
          />
          <p className="text-3xl font-bold text-center">Start Playing MGP</p>
          <div className="flex md:flex-row flex-col justify-between w-full">
            <div className="flex w-full md:w-1/3 mt-6 flex-col items-center bg-purple-600 bg-opacity-10 rounded-xl p-4">
              <img className="h-14 mb-2" src="mobcom.png" />
              <div className="flex text-center flex-col ml-4">
                <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
                  Mobile/Computer
                </h4>
                <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
                  You can play MGP in both Mobile and computer.
                </p>
              </div>
            </div>
            <div className="flex w-full md:w-1/3 ml-0 md:ml-4 mt-6 flex-col items-center bg-purple-600 bg-opacity-10 rounded-xl p-4">
              <img className="h-10 mb-2" src="TWT.png" />
              <div className="flex text-center flex-col">
                <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
                  Web3 Wallet
                </h4>
                <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
                  Any Web3 Wallet required Ex- turst wallet, metamask token
                  Pocket
                </p>
              </div>
            </div>
            <div className="flex w-full md:w-1/3 ml-0 md:ml-4 mt-6 flex-col items-center bg-purple-600 bg-opacity-10 rounded-xl p-4">
              <img className="h-10 mb-2" src="matictoken.png" />
              <div className="flex text-center flex-col">
                <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
                  Matic Token
                </h4>
                <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
                  Matic is required for transaction Fees and Investments in MGP.
                </p>
              </div>
            </div>
          </div>
        </div>
 
        <div className="relative mt-36 md:mt-48 px-3 md:px-0">
          <img
            className="absolute top-32 md:block hidden md:top-0 -z-10 md:min-w-auto min-w-[745px]"
            src="home-foot.jpg"
          />          
          <img
            className="absolute top-64 block md:hidden -z-10 min-h-[500px]"
            src="home-footmob.jpg"
          />          
          <div className="w-full mt-10 md:px-4 flex flex-col md:flex-row justify-center items-center">
            <img src='features.png' className='h-[380px] mt-8 mr-0 md:mr-40' />
            <div className="md:w-1/2 w-full">
              <p className="text-gradient mb-3 text-center text-2xl font-bold">Features</p>
              <div className="flex flex-row bg-purple-600 bg-opacity-10 rounded-xl p-4">
                <EmojiEventsIcon fontSize="large" color="inherit" />
                <div className="flex flex-col ml-4">
                  <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
                    Rewards
                  </h4>
                  <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
                  Our decentralized platform offers a variety of rewards for users who contribute to our ecosystem.
                  </p>
                </div>
              </div>

              <div className="flex mt-4 flex-row bg-purple-600 bg-opacity-10 rounded-xl p-4">
                <VpnLockIcon fontSize="large" color="inherit" />
                <div className="flex flex-col ml-4">
                  <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
                    100% Secure
                  </h4>
                  <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
                  Our platform is built on smart contracts, providing a high level of security for all users.
                  </p>
                </div>
              </div>

              <div className="flex mt-4 flex-row bg-purple-600 bg-opacity-10 rounded-xl p-4">
                <CasinoIcon fontSize="large" color="inherit" />
                <div className="flex flex-col ml-4">
                  <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
                    Guessing Pool
                  </h4>
                  <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
                  Users can guess the amount of incoming matic within 24 hours and place bet also earn rewards in Global Guessing Pool.
                  </p>
                </div>
              </div>

              <div className="flex mt-4 flex-row bg-purple-600 bg-opacity-10 rounded-xl p-4">
                <MilitaryTechIcon fontSize="large" color="inherit" />
                <div className="flex flex-col ml-4">
                  <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
                    Royalty
                  </h4>
                  <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">  
                  Our platform offers a chance for users to be part Global Royalty Pool which has 0.5% global Deposits.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative mt-44 p-6 flex flex-col md:flex-row items-start">
          <img
            className="absolute bottom-0 w-full opacity-40"
            src="red-blur.png"
          />
          <img src="metaverse.png" className="h-96 -z-20" />
          <div className="md:ml-8 ml-0 md:mt-0 mt-4">
            <h1 className="text-2xl font-bold mont">Play and Earn with the most Trusted Platform</h1>
            <p className="mt-6 mont text-xl flex justify-between font-bold bg-purple-600 bg-opacity-10 rounded-xl p-4">Jackpot <span className="text-lime-500">{parseInt(jackpot)}+</span></p>
            <p className="mt-6 mont text-xl flex justify-between font-bold bg-purple-600 bg-opacity-10 rounded-xl p-4">Winners <span className="text-lime-500">{winners}+</span></p>
            <p className="mt-6 mont text-xl flex justify-between font-bold bg-purple-600 bg-opacity-10 rounded-xl p-4">Players <span className="text-lime-500">{players}+</span></p>
          </div>
        </div>

        <div className="mt-32 flex w-full px-4 flex-col relative items-center">
            <h1 className="text-5xl font-extrabold mont"><span className="text-violet-500">Matic</span> Game Player!</h1>
            {/* <Link to={"/dashboard"}> */}
              <button onClick={login} className="mt-12 bg-[#6415ff] rounded-full font-bold text-lg transition-all hover:bg-[#4801d6] px-6 mont py-3">
                Contribute
              </button>
            {/* </Link> */}
          </div>

      </div>
    </>
  );
};

export default Homepage;
