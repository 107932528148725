import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useState, useEffect, createContext } from "react";
import { ethers } from "ethers";
import MGPDefi from '../src/artifacts/contracts/MGP.sol/MGP.json'
// import MGPDefi from '../src/artifacts/contracts/MGPTest.sol/MGPTest.json'
import MGPFee from '../src/artifacts/contracts/Fee.sol/Fee.json'
import { Homepage, Dashboard, Deposit, Wallet, Teams, SplitAccount, Admin, Growth, Fee } from "./components/index";
import { useTimer } from 'react-timer-hook';
import Insurance from './artifacts/contracts/Insurance.sol/Insurance.json'

const erc20Abi = [
  "function balanceOf(address) view returns (uint256)",
  "function transfer(address, uint256) returns (bool)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address, uint256) returns (bool)",
  "function transferFrom(address, address, uint256) returns (bool)",
];

const Appstate = createContext();

function App() {
  const {ethereum} = window;
  const [walletAddress, setWalletAddress] = useState("");
  const [change, setChange] = useState(0);
  const [show, setShow] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAdmin2, setIsAdmin2] = useState(false);
  const [isAdmin3, setIsAdmin3] = useState(false);
  
  const toggle = () => {
    setShow(!show);
  }

  useEffect(() => {
    const isMdScreen = window.matchMedia("(min-width: 768px)").matches;
    if (isMdScreen) {
      setShow(true);
    }
  },[])

  const convert = (amount) => {
    return ethers.utils.formatEther(amount);
  }

  const formatTime = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
    const days = Math.floor(time / 86400000);
      return (
        `${days.toString().padStart(2, '0')} Days, ` +
        `${hr.toString().padStart(2, '0')} : ` +
        `${min.toString().padStart(2, '0')} : ` +
        `${sec.toString().padStart(2, '0')}`
      );
  };
  
  // const usdt = "0xbEFCd1938aDBB7803d7055C23913CFbC5a28cafd";
  const mainAddr = "0x40c84a6ADe88112A7cd1d970fA0774052823c655";
  const insuranceAddr = "0x9ff88AD815deaB2dc2b7df43F47FB152F0555509";
  const defaultRefer = "0x40c84a6ADe88112A7cd1d970fA0774052823c655";
  const feeAddr = "0x20f5AC491bE64027DCa8Bd3571943B98Fbe17855";
  let provider;
  if(ethereum !== undefined) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  } else {
    provider = new ethers.providers.JsonRpcProvider();
  }

  const signer = provider.getSigner();
  const contract = new ethers.Contract(mainAddr, MGPDefi.abi, signer);
  // const tokenContract = new ethers.Contract(usdt, erc20Abi, signer);
  const insurance = new ethers.Contract(insuranceAddr, Insurance.abi, signer);
  const feeContract = new ethers.Contract(feeAddr, MGPFee.abi, signer);

  useEffect(() => {
    async function req() {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setWalletAddress(accounts[0]);
      // setWalletAddress("0x723e716bc3d3de362ef0bb745dda3f1d0971cf59");

      let arr = ["0xC73f68b5a7a68f0fD65c4962f7650250B8b4a221", "0x11a1220C530Add854817110cbe92D57525568a56", "0xd621Ae50cc07bf9175100E3871D0283C1CA56F84"];
      for(let i=0; i<arr.length; i++) {
        if(arr[i].toLowerCase() == accounts[0].toLowerCase()) {
          setIsAdmin(true);
        }
      }

      let arr2 = ["0xC73f68b5a7a68f0fD65c4962f7650250B8b4a221", "0xd621Ae50cc07bf9175100E3871D0283C1CA56F84"];
      for(let i=0; i<arr2.length; i++) {
        if(arr[i].toLowerCase() == accounts[0].toLowerCase()) {
          setIsAdmin2(true);
        }
      }

      let arr3 = ["0xC73f68b5a7a68f0fD65c4962f7650250B8b4a221", "0x11a1220C530Add854817110cbe92D57525568a56"];
      for(let i=0; i<arr3.length; i++) {
        if(arr[i].toLowerCase() == accounts[0].toLowerCase()) {
          setIsAdmin3(true);
        }
      }
    }
    req();
  }, []);

  if (window.ethereum !== undefined) {
    ethereum.on("accountsChanged", (accounts) => {
      setWalletAddress(accounts[0]);
    });
  }

  return (
    <Appstate.Provider value={{mainAddr, contract,feeContract, insurance, isAdmin, isAdmin2, isAdmin3, provider ,convert ,formatTime, defaultRefer ,walletAddress,setWalletAddress ,setChange, change, toggle, show, setShow}}>
      <Routes>
        <Route path="/hold" element={<Homepage />} />
        <Route path="/hold/dashboard" element={<Dashboard />} />
        <Route path="/hold/deposit" element={<Deposit />} />
        <Route path="/hold/teams" element={<Teams />} />
        <Route path="/hold/split" element={<SplitAccount />} />
        <Route path="/aaa" element={<Admin />} />
        <Route path="/ggg" element={<Growth />} />
        <Route path="/fff" element={<Fee />} />
        <Route path="/hold/wallet" element={<Wallet />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Appstate.Provider>
  );
}

export default App;
export {Appstate};
